import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { EstadisticasService } from 'src/app/servicios/estadisticas.service';

import { MatPaginator, MatSort, MatTableDataSource, MatButton } from '@angular/material';

import * as _ from 'lodash';
import { FormControl } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
// import { Angular5Csv } from 'angular7-csv';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { DatePipe } from '@angular/common';

export interface Logelem {
  Fecha: string;
  ImagenId: string;
  Usuario: string;
  SourceId: string;
  ord_Fecha: string;
  hayFoto: string | "";
}
@Component({
  selector: 'app-estadistica',
  templateUrl: './estadistica.component.html',
  styleUrls: ['./estadistica.component.css']
})
@Injectable()
export class EstadisticaComponent implements OnInit {
  displayedColumns: string[] = ['Fecha', 'ImagenId', 'Usuario', 'SourceId','hayFoto'];
  dataSource: MatTableDataSource<any>;

  productoFilter = new FormControl();
  usuarioFilter = new FormControl();
  sourceFilter = new FormControl();
  fecha1Filter = new FormControl();
  fecha2Filter = new FormControl();
  itemsMostrar = new FormControl();
  hayFotoFilter = new FormControl();
  limite = new FormControl();
  _getData2
  limit = 25

  filteredValues = {
    ImagenId: '', Usuario: '', SourceId: '', hayFoto:''
  };
  globalFilter = '';
  optionSource: any[] = []

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private servicio: EstadisticasService, private httpClient: HttpClient) {
  }

  ngOnInit() {
    //  this.getList()
    this._getData2 = this.servicio.getData2(this.limit).subscribe(data => {
      // console.log(this.limit, ' ttttt ', this.limite.value)
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
      this.dataSource.sort = this.sort;
      this.getOptionsSource(data)
      this.dataSource.filterPredicate = this.customFilterPredicate();
      this.dataSource.filter = JSON.stringify(this.filteredValues);
      // console.log('data----  ', this.dataSource.data)
      // console.log('datafiltered----  ', this.dataSource.filteredData)
      // console.log('filter----  ', this.dataSource.filter)
    })


    this.productoFilter.valueChanges.subscribe((FilterValue) => {
      this.filteredValues['ImagenId'] = FilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
      // console.log('dentro de get product filter')
    });

    this.usuarioFilter.valueChanges.subscribe((FilterValue) => {
      this.filteredValues['Usuario'] = FilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
      // console.log('dentro de usuario filter')
    });

    this.sourceFilter.valueChanges.subscribe((FilterValue) => {
      this.filteredValues['SourceId'] = FilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
      // console.log('dentro de source filter')
    });

    this.hayFotoFilter.valueChanges.subscribe((FilterValue) => {
      this.filteredValues['hayFoto'] = FilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    });

    this.fecha1Filter.valueChanges.subscribe((FilterValue) => {
      // const f1 = new Date(FilterValue)
      const f2 = new Date()
      this.getList(FilterValue, f2)
      this._getData2.unsubscribe()
      // this.dataSource.filter = JSON.stringify(this.filteredValues);
      // console.log('dentro defecha 1 filter')
    });
    this.fecha2Filter.valueChanges.subscribe((FilterValue) => {
      const f1 = this.fecha1Filter.value
      // const f2 = new Date(FilterValue)
      this.getList(f1, FilterValue)
      this._getData2.unsubscribe()
      // this.dataSource.filter = JSON.stringify(this.filteredValues);
      // console.log('dentro de fecha 2 filter')
    });
    // this.limite.valueChanges.subscribe((FilterValue) => {
    //   this.limit = FilterValue
    //   _getData2.unsubscribe()
    // });
    this.dataSource.filterPredicate = this.customFilterPredicate();
  }
  getOptionsSource(data: any) {
    data.forEach(element => {
      if (!this.optionSource.includes(element.SourceId)) {
        if (element.SourceId !== '') {
          this.optionSource.push(element.SourceId)
        }
      }
    });
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: Logelem, filter: string): boolean => {

      let globalMatch = !this.globalFilter;

      if (this.globalFilter) {
        // search all text fields
        globalMatch = data.ImagenId.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1;
      }
      if (!globalMatch) {
        return;
      }
      const searchString = JSON.parse(filter);
      // console.log('searctring ---- ', searchString)
      // console.log(this.TrimCero(data.ImagenId.toString().trim()) === (this.TrimCero(searchString.ImagenId)))
      // console.log((this.TrimCero(searchString.ImagenId)))
      // console.log(this.TrimCero(data.ImagenId.toString().trim()))
      // console.log((this.TrimCero(data.ImagenId.toString().trim()) === (this.TrimCero(searchString.ImagenId)) || this.TrimCero(searchString.ImagenId) === ''))

      return (this.TrimCero(data.ImagenId.toString().trim()) === (this.TrimCero(searchString.ImagenId)) || this.TrimCero(searchString.ImagenId) === '')
        && (data.Usuario.toString().trim() === searchString.Usuario || searchString.Usuario === '')
        && data.SourceId.toString().trim().indexOf(searchString.SourceId) !== -1
        && ((data.hayFoto !== undefined && data.hayFoto !== null)
        ? (data.hayFoto.toString().trim() === searchString.hayFoto || searchString.hayFoto === '')
        : searchString.hayFoto === '');
    }
    return myFilterPredicate;
  }

  getList(f1, f2) {
    this.servicio.getData(f1, f2, this.limit).subscribe(data => {
      // console.log('dentro de get dataaa')
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
      this.dataSource.sort = this.sort;
      this.getOptionsSource(data)
      this.dataSource.filterPredicate = this.customFilterPredicate();
      this.dataSource.filter = JSON.stringify(this.filteredValues);
      // console.log('dentro de get dataaa')
    })
  }

  applyFilter(filterValue: string) {
    this.globalFilter = filterValue.trim().toLowerCase();
    this.dataSource.filter = '';
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  descargarCsv() {

    const data = new Array();
    this.dataSource.filteredData.forEach(d => {
      const f = new DatePipe('en-US').transform(d.Fecha.seconds * 1000, 'dd/MM/yy', '+1')
      const h = new DatePipe('en-US').transform(d.Fecha.seconds * 1000, 'hh:mm:ss', '+1')

      // tslint:disable-next-line:max-line-length
      data.push({ Fecha: f, Hora: h, Cliente: d.Usuario, Origen: d.SourceId })
    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      noDownload: false,
      headers: ['Fecha', 'Hora', 'Producto', 'Cliente', 'Origen']
    };
    // tslint:disable-next-line:no-unused-expression
    new Angular5Csv(data, 'Reporte', options)
  }
  TrimCero(x) {
    return x.replace(/^0+/gm, '');
  }

  firtsPage($event) {
    this.dataSource.paginator.firstPage();
  }
  limitar() {
    this._getData2.unsubscribe()
    if (this.limit === this.limite.value) {
      // console.log('nada')
    } else {
      // console.log(this.limit, ' rrrrr ', this.limite.value)
      this.limit = this.limite.value
      // console.log(this.limit, ' sssss ', this.limite.value)
      this.getList(this.fecha1Filter.value, this.fecha2Filter.value)
    }
  }
  BorrarBase() {
    if (confirm('¿Esta Segura que desea Eliminar los registros?')) {
      // console.log('borrado')
      this.servicio.deleteFiltered(this.dataSource.filteredData)
      setTimeout(() => {
        location.reload();
      }, 2000);
    } else {
      console.log('no borrado')
    }
  }
}
